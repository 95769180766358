
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import MainHeroAnimatedBackground from '~/domains/main/components/MainHeroAnimatedBackground';

export default defineComponent({
  name: 'MainHeroSliderSection',
  components: { MainHeroAnimatedBackground, Swiper, SwiperSlide },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const slider = ref();
    const heroSection = ref();
    const imageElement = ref([]);
    const textElement = ref([]);
    const { $gsap } = useContext();
    const router = useRoute();
    const activeIndex = ref(0);

    const isSlideHidden = (index) => {
      return index > 0 && (!slider.value || !slider.value.$swiper);
    };

    const animateOnScroll = () => {
      const instance = $gsap.matchMedia();
      instance.add('(min-width: 1024px)', () => {
        textElement.value.forEach((el) => {
          $gsap.fromTo(
            el,
            {
              y: 0,
            },
            {
              y: -50,
              ease: 'power3.out',
              scrollTrigger: {
                scrub: 1,
                trigger: heroSection.value.$el,
                start: 'top top',
                end: '40% 200px',
                toggleActions: 'restart none none reverse',
              },
            }
          );
        });

        imageElement.value.forEach((el) => {
          $gsap.fromTo(
            el,
            {
              y: 0,
            },
            {
              y: 50,
              ease: 'power3.out',
              scrollTrigger: {
                scrub: 1,
                trigger: heroSection.value.$el,
                start: '-95 top',
                end: 'bottom 200px',
                toggleActions: 'restart none none reverse',
              },
            }
          );
        });
      });

      instance.add('(max-width: 1023px)', () => {
        textElement.value.forEach((el) => {
          $gsap.fromTo(
            el,
            {
              y: 0,
            },
            {
              y: -20,
              ease: 'power3.out',
              scrollTrigger: {
                scrub: 1,
                trigger: heroSection.value.$el,
                start: 'top top',
                end: '50% 200px',
                toggleActions: 'restart none none reverse',
              },
            }
          );
        });

        imageElement.value.forEach((el) => {
          $gsap.fromTo(
            el,
            {
              y: 0,
            },
            {
              y: 50,
              ease: 'power3.out',
              scrollTrigger: {
                scrub: 1,
                trigger: heroSection.value.$el,
                start: 'top top',
                end: 'bottom 200px',
                toggleActions: 'restart none none reverse',
              },
            }
          );
        });
      });
    };
    const handleSwiperChange = (swiper) => {
      activeIndex.value = swiper.activeIndex;
    };

    const scrollTo = (hash) => {
      $gsap.to(window, { scrollTo: hash });
    };
    const handleSliderPause = () => {
      slider.value.$swiper.autoplay.pause();
    };
    const handleSliderRun = () => {
      slider.value.$swiper.autoplay.run();
    };

    onMounted(() => {
      if (router.value.hash) {
        scrollTo(router.value.hash);
      }

      animateOnScroll();
      slider.value.$swiper.on('slideChange', () => {
        activeIndex.value = slider.value.$swiper.activeIndex;
      });
      slider.value.$el.addEventListener('mouseenter', handleSliderPause);
      slider.value.$el.addEventListener('mouseleave', handleSliderRun);
    });
    onUnmounted(() => {
      slider.value?.$el.removeEventListener('mouseenter', handleSliderPause);
      slider.value?.$el.removeEventListener('mouseleave', handleSliderRun);
    });

    return {
      heroSection,
      imageElement,
      textElement,
      scrollTo,
      slider,
      handleSwiperChange,
      activeIndex,
      isSlideHidden,
    };
  },
});
