
import { ref, computed, watch, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    active: {
      type: Number,
      default: 0,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    length: {
      type: Number,
      default: 96,
    },
    showActiveIndex: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const itemsLength = ref(props.count);
    const heightFullBetweenDots = ref(props.length);

    const lineHeight = ref(70);
    const itemsTopPosition = ref([]);

    const activeIndex = computed(() => {
      return props.active;
    });

    const gap = computed(() => {
      return heightFullBetweenDots.value / itemsLength.value;
    });

    const heightFullWithLine = computed(() => {
      return gap.value * (itemsLength.value - 1) + lineHeight.value;
    });

    const linePosition = computed(() => {
      return activeIndex.value ? gap.value * activeIndex.value : 0;
    });

    const updateItemsTopPosition = () => {
      itemsTopPosition.value = [];
      let currentGap = 0;
      for (let i = 0; i < itemsLength.value; i += 1) {
        if (i === 0) {
          itemsTopPosition.value.push(currentGap);
        } else if (activeIndex.value + 1 === i || activeIndex.value === i) {
          if (
            activeIndex.value === 0 ||
            activeIndex.value === itemsLength.value - 1
          ) {
            currentGap += lineHeight.value + gap.value;
            itemsTopPosition.value.push(currentGap);
          } else {
            currentGap += lineHeight.value / 2 + gap.value;
            itemsTopPosition.value.push(currentGap);
          }
        } else {
          currentGap += gap.value;
          itemsTopPosition.value.push(currentGap);
        }
      }
    };

    updateItemsTopPosition();

    watch(
      () => props.active,
      () => {
        updateItemsTopPosition();
      }
    );

    const changeIndex = () => {
      activeIndex.value = 1;
    };

    return {
      activeIndex,
      heightFullBetweenDots,
      heightFullWithLine,
      gap,
      itemsLength,
      itemsTopPosition,
      linePosition,
      changeIndex,
      updateItemsTopPosition,
    };
  },
});
